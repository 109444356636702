@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.live-chat-button {
  display: block;
  margin: 0 auto;
  width: 260px;
  background-color: var(--sp-color-accent-two);
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  padding: $spacer;
  text-align: center;
  transition: all .25s;
  padding-top: 8px;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.4);
  margin-bottom: -80px;

  a {
    color: #fff;
    font-family: var(--sp-font-secondary);
    display: block;

    &::after {
      display: none;
      content: none !important;
    }

    .top {
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .bottom {
      font-family: var(--sp-font-primary);
      font-style: italic;
      font-size: 15px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(md) {
    position: fixed;
    margin-bottom: 0;
    right: 40px;
    bottom: -37px;
    cursor: pointer;

    &:hover {
      bottom: -1px;
      padding-top: 30px;
      padding-bottom: 30px;

      // .bottom {
      //   display: inline;
      // }
    }
  }
}