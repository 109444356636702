@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.service {
  h1 {
    margin: 0;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: $spacer * 2;
  }
  .related-list-heading {
    margin-top: $spacer * 4;
  }

  .button {
    border: none;
    background-color: var(--sp-color-accent-one);
    color: var(--sp-color-primary);
    padding-top: $spacer * 0.8;
    padding-bottom: $spacer * 0.8;
    margin-bottom: $spacer * 1.8;
  }

  .help-articles-heading {
    border-top: 1px solid var(--sp-color-neutral-accent-one);
    margin-top: $spacer;

    h2 {
      display: inline;
      border-top: 3px solid var(--sp-color-accent-one);
    }
  }

  &-featured-image {
    img {
      width: 100%;
      margin-bottom: $spacer * 2;
    }
  }

  &__metabox {
    box-shadow: 2.2em 2.2em var(--sp-color-secondary);
    border: 8px solid var(--sp-color-neutral-accent-one);
    overflow-wrap: break-word;
    padding: 0 $spacer $spacer $spacer;
    margin-right: 2.2em;
    margin-bottom: $spacer * 4;

    h3 {
      margin: $spacer 0 -4px 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.71;
      color: var(--sp-color-primary);
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    ul,
    li {
      list-style: none;
      padding-inline-start: 0;
      padding-left: 0;
    }

    li {
      line-height: 1.4;
      padding-top: 9px;
    }
  }

  &__categories {
    color: var(--sp-color-link);
    font-family: var(--sp-font-secondary);
    font-style: italic;
    font-size: 18px;
    margin-bottom: $spacer * 2;
  }

  &-teaser {
    border: solid 1px var(--sp-color-neutral-accent-one);
    padding: 0 $spacer $spacer $spacer;
    margin: 0 $spacer $spacer 0;
    background-color: #fff;

    @include media-breakpoint-up(xl) {
      margin: 0 $spacer $spacer * 2 $spacer;
    }

    h4 {
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 24px;
    }

    &-categories {
      font-style: italic;
      font-size: 16px;
      font-family: var(--sp-font-secondary);
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }

    .summary {
      margin-top: $spacer;
    }
  }
}

// List page
.services-list-page {
  .search-interface .search-facets {
    width: 97%;

    @include media-breakpoint-up(md) {
      width: 378px;
    }
  }
  .main-content {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    width: 100%;

    // @include media-breakpoint-up(md) {
    //   padding-left: $spacer;
    //   width: 44.4%;
    // }

    @include media-breakpoint-up(xl) {
      padding-left: $spacer;
      width: 66.6%;
    }
  }
  .meta-box {
    box-shadow: none;
    border: none;
    background-color: #efeeee;
  }

  .search-results {
    ul {
      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }
  }
}

// Services list & content reference paragraph
.paragraph__servicelist,
.paragraph__content-reference {
  padding-top: $spacer * 2;

  .service-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .service-teaser {
    width: 46.5%;
    margin: $spacer $spacer $spacer * 3 0;
    box-shadow: var(--sp-color-secondary) $spacer * 2 $spacer * 2;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.paragraph__content-reference .service-teaser {
  margin-bottom: $spacer * 4;
  margin-top: $spacer * 2;
}

.paragraph__threecolumn,
.paragraph__twocolumn {
  .service-teaser {
    width: 90%;
  }
}
