@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.logo-desktop {
  @include media-breakpoint-up(lg) {
    width: 55%;
  }
}

header .search-form {
  @include media-breakpoint-up(lg) {
    width: 22%;
  }
}

header .navbar-brand.logo-mobile {
  @include media-breakpoint-down(lg) {
    width: 65%;
  }
}

// order overrides for very small screens
@include media-breakpoint-down(sm) {
  header .navbar-brand.logo-mobile {
    order: 1;
    width: 100%;
  }

  .header-it-status-wrapper.mobile {
    order: 2;
  }

  header .navbar-toggler {
    order: 3;
  }

  header .header_wrapper .container {
    order: 4;
  }
}