@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.header-it-status-wrapper {
  font-family: var(--sp-font-secondary);
  font-size: 15px;
  order: 1;
  text-align: right;
  line-height: 1.4;

  &.mobile {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
      position: absolute;
      right: 5px;
      top: 20px;
      
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      text-align: center;
      margin: auto;
      right: auto;
      top: 3px;
      margin-bottom: 10px;
    }
  }

  &.desktop {
    display: none;
    width: 14%;
    @include media-breakpoint-up(lg) {
      display: block;
      order: 4;
    }
  }

  a {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #ffffff4f;

    span {
      text-decoration: none;
    }

    &:hover {
      text-decoration-color: #fff;
    }

    &::after {
      display: none;
      content: none !important;
    }
  }

  .service-status {
    color: var(--sp-color-accent-one);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;

    span {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 4px;
      border-radius: 100%;
      border: solid 1px #fff;
      background-color: darkgrey;

      &.none,
      &.maintenance {
        background-color: #2fcc66;
      }

      &.minor {
        background-color: #f1c40f;
      }

      &.major {
        background-color: #e67e22;
      }

      &.critical {
        background-color: #e74c3c;
      }
    }
  }
}
