@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.help-article {
  &-categories {
    font-style: italic;
    font-size: 16px;
    font-family: var(--sp-font-secondary);
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  }
  &-teaser {
    .heading {
      margin-bottom: 0;
      text-transform: capitalize;
    }
    .summary {
      margin-top: $spacer;
    }
  }
}
